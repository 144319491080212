<template>
    <div class="mb-24">
        <loader
            class="z-50"
            :loading="loading"
            :backdrop="true"
        />
        <div class="w-full bg-white flex flex-col flex-1 flex fixed bottom-0 py-4 px-8">
            <h2 class="font-bold text-start my-4 text-base">
                Application status:
                <span class="text-purple-600">{{ application_status }}</span>
            </h2>
        </div>
        <div
            v-if="hasCredentials"
            id="bluemoon-application-container"
        ></div>
        <div
            v-else
            class="w-full text-center align-center mt-4"
        >
            <p>please set up the blue moon property to continue</p>
        </div>
    </div>
</template>

<script type="text/javascript">
import Loader from '@/components/ui/Loader';
import NotifyMixin from '@/mixins/NotifyMixin';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { formatQuotedRentAmount } from '@/utils/Amount';

import {
    UPDATE_APPLICATION_ERROR,
    UPDATE_DOCUMENT_INSTANCE_ERROR,
    SAVE_DOCUMENT_INSTANTCE_ERROR,
    BM_CREDENTIALS_ERROR,
} from '@/utils/constants/leasing.js';

export default {
    name: 'ApplicationForm',
    components: { Loader },

    mixins: [NotifyMixin],
    data() {
        return {
            isCompleted: false,
            applicationCompleted: false,
            loading: false,
            token: '',
            id: '',
            preFillAppData: null,
            hasCredentials: false,
            application_status: 'Application not Submitted',
            property: {
                id: '',
                name: '',
            },

            params: {
                view: 'application',
                skipStartPage: true,

                applicationData: {
                    ...this.preFillAppData,
                },
            },

            document_instance_created: false,
        };
    },

    computed: {
        ...mapGetters({
            getDocumentInstance: 'application/getDocumentInstance',
            getApplication: 'application/getApplication',
            currentProfile: 'auth/currentProfile',
            getApplicationId: 'application/getApplicationId',
            getbluemoon_credentials: 'application/getbluemoon_credentials',
            getOccupancyData: 'leasing/getOccupancyData',
        }),

        getLeaseTerms() {
            const diff = moment(this.getOccupancyData.phaseEndDate).diff(moment(this.getOccupancyData.phaseStartDate), 'months', true);
            return `${Math.floor(diff)} months`;
        },

        getIdentityResult() {
            return this.getApplication.id_verification_result === 'Completed';
        },
    },

    async mounted() {
        this.loading = true;
        await this.fetchApplicationByContactId(this.currentProfile.occupancyId);
        const currentApplicant = this.getApplication?.persons.find(person => person.contact_id === this.currentProfile.contactId);
        if (
            this.getApplication &&
            currentApplicant?.application[0]?.application.document_package_instance_id &&
            currentApplicant?.application[0]?.application
        )
            this.document_instance_created = true;

        setTimeout(() => {
            if (
                currentApplicant?.applicationProcessStatus[0]?.application_process_status.toLowerCase() ===
                'application submitted - not paid'
            ) {
                this.$router.push({ name: 'application_service.application_payment' });
            } else if (
                currentApplicant?.applicationProcessStatus[0]?.application_process_status.toLowerCase() === 'background screening' ||
                currentApplicant?.applicationProcessStatus[0]?.application_process_status.toLowerCase() === 'mitigation' ||
                currentApplicant?.applicationProcessStatus[0]?.application_process_status.toLowerCase() === 'accepted' ||
                currentApplicant?.applicationProcessStatus[0]?.application_process_status.toLowerCase() === 'declined' ||
                (currentApplicant?.applicationProcessStatus[0]?.application_process_status.toLowerCase() === 'identity verification' &&
                    this.getIdentityResult)
            ) {
                this.$router.push({ name: 'application_service.confirmation' });
            } else if (
                currentApplicant?.applicationProcessStatus[0]?.application_process_status.toLowerCase() ===
                    'application submitted - paid' ||
                (currentApplicant?.applicationProcessStatus[0]?.application_process_status.toLowerCase() === 'identity verification' &&
                    !this.getIdentityResult)
            ) {
                this.$router.push({ name: 'application_service.pre_evaluation_form' });
            } else this.$nextTick(this.fetchbluemoon_credentials);
        }, 2500);
    },

    beforeMount: function () {
        this.clearState();
    },

    methods: {
        ...mapActions({
            setDocumentInstance: 'application/setDocumentInstance',
            setApplication: 'application/setApplication',
            clearState: 'application/clearState',
            setApplicationId: 'application/setApplicationId',
            setbluemoon_credentials: 'application/setbluemoon_credentials',
        }),

        formatQuotedRentAmount,

        fetchbluemoon_credentials() {
            this.$partnerDataProvider
                .getOne('community_blue_moon', {
                    customerUUID: this.currentProfile.customerId,
                    communityUUID: this.currentProfile.communityId,
                    purpose: 'AplicationLeasingGeneration',
                })
                .then(res => {
                    if (res.platformData) {
                        this.hasCredentials = true;
                        this.setbluemoon_credentials(res.platformData);
                        this.getAuthBlueMoonToken();
                    }
                })
                .catch(() => {
                    this.hasCredentials = false;
                    this.notifyError(BM_CREDENTIALS_ERROR);
                })
                .finally((this.loading = false));
        },

        getAuthBlueMoonToken() {
            this.loading = true;
            this.$bmServiceDataProvider
                .create('authTokenBM', {
                    data: {
                        username: this.getbluemoon_credentials?.username,
                        password: this.getbluemoon_credentials?.password,
                        scope: 'full',
                        grant_type: 'password',
                        client_id: this.getbluemoon_credentials?.client_id,
                        client_secret: this.getbluemoon_credentials?.client_secret,
                    },
                })
                .then(async res => {
                    if (this.$route.params.id) this.id = this.$route.params.id;
                    this.token = res.access_token;
                    await this.getProperty(this.getbluemoon_credentials?.serial_number, res.access_token);
                })
                .catch(() => {
                    this.notifyError('An authentication error occurred with Blue Moon');
                    this.loading = false;
                });
        },

        getProperty(account_id, token) {
            this.$bmServiceDataProvider
                .getList('property', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(async res => {
                    this.property = res.data.find(property => property.account_id === account_id);
                    await this.leaseApplication();
                })
                .catch(() => {
                    this.notifyError('An error occurred getting the property from Blue Moon');
                    this.loading = false;
                });
        },

        leaseApplication() {
            // eslint-disable-next-line no-undef
            const bluemoonElements = new BluemoonElements(this.token, this.property.id);
            const applicationElement = bluemoonElements.load('bluemoon-application-container', 'bm-rental-application', {
                ...this.params,
                applicationData: this.getDocumentInstance ? { ...this.getDocumentInstance } : { ...this.preFillAppData },
            });

            applicationElement.addEventListener('onElementLoaded', e => {
                if (e.detail.elementId) this.loading = false;
            });

            applicationElement.addEventListener('onApplicationComplete', e => {
                if (!e.detail.completed) return;
                const errors = this.checkRequiredFields();
                if (errors.length) {
                    errors.forEach(error => this.notifyError(error));
                } else {
                    this.$router.push({ name: 'application_service.review-application' });
                }
            });

            applicationElement.addEventListener('onApplicationSaved', async e => {
                const application = {
                    ...this.getDocumentInstance,
                    ...e.detail.data,
                    work_phone: e.detail.data.current_employer_phone,
                    id: e.detail.id,
                    property_id: e.detail.property_id,
                    property_name: this.property.name,
                    esign_id: e.detail.esign_id,
                    signed: e.detail.signed,
                    unit_number: e.detail.unit_number,
                    has_credit_ach: e.detail.has_credit_ach,
                    disclosures: e.detail.disclosures,
                };

                await this.save(application);
            });
            applicationElement.addEventListener('onReviewComplete', e => {
                const application = {
                    ...this.getDocumentInstance,
                    completed: e.detail.completed,
                    transactionId: e.detail.transactionId,
                    work_phone: e.detail.data.current_employer_phone,
                };
                this.setDocumentInstance(application);
            });
            applicationElement.addEventListener('onEsignatureComplete', async e => {
                const application = {
                    ...this.getDocumentInstance,
                    signed: e.detail.completed,
                    document_url: e.detail.document_url,
                };
                await this.save(application);
            });
        },

        async updateApplicationRecord(
            document_instance,
            process_status = 'In progress',
            event_description = 'The application has started',
            event_name = 'Application in progress'
        ) {
            this.loading = true;
            const new_application = { ...this.getApplication };
            delete new_application.application_id;
            delete new_application.occupancy_id;
            delete new_application.person_id;
            delete new_application.pets;
            const currentApplicant = new_application.persons?.find(person => person.contact_id === this.currentProfile.contactId);
            const currentParty = new_application.parties?.find(party => party.contact_id === this.currentProfile.contactId);
            const allPersons = [...new_application.persons];
            delete new_application.persons;

            try {
                await this.$leasingServiceDataProvider.patch('application', {
                    application_id: currentApplicant?.application[0]?.application.application_id ?? this.getApplicationId,
                    data: {
                        ...new_application,
                        occupancy_id: this.currentProfile.occupancyId,
                        first_name: this.getDocumentInstance.applicant_first_name,
                        last_name: this.getDocumentInstance.applicant_last_name,
                        email: this.getDocumentInstance.current_email,
                        expected_lease_duration: `${this.getApplication.expected_lease_duration}`,
                        quoted_rent_amount: this.formatQuotedRentAmount(this.getApplication.quoted_rent_amount),
                        contact_id: this.getApplication.contact_id ?? this.currentProfile.contactId,
                        lease_id: this.currentProfile.occupancyId,
                        middle_name: this.getDocumentInstance.applicant_middle_name ?? '',
                        home_phone: this.getDocumentInstance.applicant_home_phone,
                        document_url: this.getDocumentInstance.document_url,
                        birth_date: this.getDocumentInstance.applicant_birth_date,
                        marital_status: this.mapMaritalStatus(this.getDocumentInstance.applicant_marital_status),
                        application_process_status: process_status,
                        event_description,
                        event_name,
                        coapplicants: this.mapCoApplicants(allPersons),
                        occupants: this.mapOccupants(currentApplicant),
                        emergency_contact: this.mapEmergencyContact(currentApplicant, document_instance),
                        pet: this.mapPets(currentApplicant),
                        vehicle: this.mapVehicles(currentApplicant),
                        submission_date: this.getDocumentInstance.updated_at,
                        current_address: this.getDocumentInstance.current_address ?? '',
                        current_city: this.getDocumentInstance.current_city ?? '',
                        current_st: this.getDocumentInstance.current_st ?? '',
                        current_zip: this.getDocumentInstance.current_zip ?? '',
                        ssn: this.getDocumentInstance.applicant_ssn ?? '',
                        work_phone: currentApplicant?.work_phone ?? document_instance.current_employer_phone,
                        is_primary: currentParty?.is_primary,
                        person_role: currentApplicant?.person_role,
                    },
                });
                this.setApplicationId(currentApplicant?.application[0]?.application.application_id ?? this.getApplicationId);
                await this.fetchApplicationByContactId(this.currentProfile.occupancyId);
            } catch {
                this.notifyError(UPDATE_APPLICATION_ERROR);
            } finally {
                this.loading = false;
            }
        },

        mapEmergencyContact(currentApplicant, document_instance) {
            const {
                emergency_contact_name,
                emergency_contact_email,
                emergency_contact_cell_phone,
                emergency_contact_home_phone,
                emergency_contact_work_phone,
                emergency_contact_relation,
            } = document_instance;
            const initialEmergencyContactData = {
                name: emergency_contact_name,
                email: emergency_contact_email,
                cell_phone: emergency_contact_cell_phone,
                home_phone: emergency_contact_home_phone,
                work_phone: emergency_contact_work_phone,
                relation: emergency_contact_relation,
            };
            const emergencyContact =
                currentApplicant?.emergency_contact?.length && currentApplicant?.emergency_contact[0] && emergency_contact_name
                    ? {
                        person_id: currentApplicant?.emergency_contact[0]?.person_id,
                        contact_id: currentApplicant?.emergency_contact[0]?.contact_id,
                        ...initialEmergencyContactData,
                    }
                    : emergency_contact_name
                        ? { ...initialEmergencyContactData }
                        : null;

            return emergencyContact;
        },

        mapOccupants(currentApplicant) {
            const currentApplicantOccupants = currentApplicant.occupants;
            const occupantsSavedOnBluemoom = this.getDocumentInstance?.occupants?.filter(occupant => occupant?.name !== null);
            occupantsSavedOnBluemoom?.forEach(item => {
                if (item && !item?.name?.includes(' ')) {
                    item.name = `${item.name} Empty`;
                }
            });
            const uniqNames = new Set();
            currentApplicantOccupants?.forEach(occupant => uniqNames.add(`${occupant?.first_name} ${occupant?.last_name}`));
            const filteredBluemoonOccupants = occupantsSavedOnBluemoom?.filter(occupant => !uniqNames.has(occupant?.name));
            const mappedCurrentOccupants = currentApplicantOccupants?.map(item => {
                return {
                    name: `${item?.first_name} ${item?.last_name}`,
                    relation: item?.relation,
                    birth_date: item?.birth_date,
                    ssn: item?.ssn,
                    person_id: item?.person_id,
                    contact_id: item?.contact_id,
                };
            });
            const mappedNewOccupants = filteredBluemoonOccupants?.map(item => {
                return {
                    name: item?.name,
                    relation: item?.relation,
                    birth_date: item?.birth_date,
                    ssn: item?.ssn,
                };
            });
            return [...mappedCurrentOccupants, ...(mappedNewOccupants || [])];
        },

        mapVehicles(currenApplicant) {
            const vehiclesDocInstance =
                this.getDocumentInstance?.vehicles && this.getDocumentInstance?.vehicles?.filter(vehicle => vehicle.make !== null)?.length
                    ? this.getDocumentInstance?.vehicles?.filter(vehicle => vehicle.make !== null)
                    : [];
            let vehicles = [];
            switch (true) {
            case vehiclesDocInstance?.length && !currenApplicant?.vehicles?.length:
                vehicles = vehiclesDocInstance.map(vehicle => {
                    return {
                        customer_id: this.getApplication.customer_id,
                        business_unit_id: this.getApplication.business_unit_id,
                        vehicle_make: vehicle.make,
                        vehicle_year: vehicle.year,
                        vehicle_model: vehicle.model,
                        vehicle_color: vehicle.color,
                        license_plate_region: vehicle.license_st,
                        license_plate: vehicle.license_no ?? '',
                        primary_driver_party_id: currenApplicant?.person_id,
                        vehicle_type: 'n/a',
                    };
                });
                break;

            case !vehiclesDocInstance?.length && currenApplicant?.vehicles?.length:
                vehicles = currenApplicant?.vehicles?.length
                    ? currenApplicant?.vehicles.map(vehicle => {
                        return {
                            ...vehicle,
                            primary_driver_party_id: currenApplicant?.person_id,
                        };
                    })
                    : [];
                break;

            default:
                if (vehiclesDocInstance?.length > currenApplicant?.vehicles?.length) {
                    vehicles = vehiclesDocInstance.map((vehicle, i) => {
                        if (currenApplicant?.vehicles[i]?.vehicle_make === vehicle.make) {
                            return {
                                vehicle_id: currenApplicant?.vehicles[i]?.vehicle_id,
                                customer_id: this.getApplication.customer_id,
                                business_unit_id: this.getApplication.business_unit_id,
                                vehicle_make: vehicle.make,
                                vehicle_year: vehicle.year,
                                vehicle_model: vehicle.model,
                                vehicle_color: vehicle.color,
                                primary_driver_party_id: currenApplicant?.vehicles[i]?.primary_driver_party_id,
                                license_plate_region: vehicle.license_st,
                                vehicle_license_plate_text: vehicle.license_no ?? '',
                                vehicle_type: 'n/a',
                            };
                        }

                        return {
                            customer_id: this.getApplication.customer_id,
                            business_unit_id: this.getApplication.business_unit_id,
                            vehicle_make: vehicle.make,
                            vehicle_year: vehicle.year,
                            vehicle_model: vehicle.model,
                            vehicle_color: vehicle.color,
                            primary_driver_party_id: currenApplicant?.person_id,
                            license_plate_region: vehicle.license_st,
                            vehicle_license_plate_text: vehicle.license_no ?? '',
                            vehicle_type: 'n/a',
                        };
                    });
                } else {
                    vehicles = currenApplicant?.vehicles?.map((vehicleObj, i) => {
                        if (vehiclesDocInstance[i]?.make === vehicleObj.vehicle_make) {
                            return {
                                vehicle_id: vehicleObj.vehicle_id,
                                customer_id: this.getApplication.customer_id,
                                business_unit_id: this.getApplication.business_unit_id,
                                vehicle_make: vehiclesDocInstance[i].make,
                                vehicle_year: vehiclesDocInstance[i].year,
                                vehicle_model: vehiclesDocInstance[i].model,
                                vehicle_color: vehiclesDocInstance[i].color,
                                primary_driver_party_id: vehicleObj.primary_driver_party_id,
                                license_plate_region: vehiclesDocInstance[i].license_st,
                                vehicle_license_plate_text: vehiclesDocInstance[i].license_no ?? '',
                                vehicle_type: 'n/a',
                            };
                        }
                        return {
                            vehicle_id: vehicleObj.vehicle_id,
                            customer_id: this.getApplication.customer_id,
                            business_unit_id: this.getApplication.business_unit_id,
                            vehicle_make: vehicleObj.vehicle_make,
                            vehicle_year: vehicleObj.vehicle_year,
                            vehicle_model: vehicleObj.vehicle_model,
                            vehicle_color: vehicleObj.vehicle_color,
                            primary_driver_party_id: currenApplicant?.person_id,
                            license_plate_region: vehicleObj.license_plate_region,
                            vehicle_license_plate_text: vehicleObj.vehicle_license_plate_text ?? '',
                        };
                    });
                }
                break;
            }

            return vehicles;
        },

        mapPets(currenApplicant) {
            const petsDocInstance =
                this.getDocumentInstance?.pets && this.getDocumentInstance?.pets?.filter(pet => pet.name !== null)?.length
                    ? this.getDocumentInstance?.pets?.filter(pet => pet.name !== null)
                    : [];

            let pets = [];
            switch (true) {
            case petsDocInstance?.length && !currenApplicant?.pets?.length:
                pets = petsDocInstance.map(pet => {
                    return {
                        customer_id: this.getApplication.customer_id,
                        business_unit_id: this.getApplication.business_unit_id,
                        pet_weight: pet.weight,
                        pet_breed: pet.breed,
                        pet_owner_party_id: currenApplicant?.person_id,
                        pet_species: pet.name,
                        pet_sex: null,
                        age: pet.age,
                    };
                });
                break;

            case !petsDocInstance?.length && currenApplicant?.pets?.length:
                pets = currenApplicant?.pets?.length ? currenApplicant?.pets : [];
                break;

            default:
                if (petsDocInstance?.length > currenApplicant?.pets?.length) {
                    pets = petsDocInstance.map((pet, i) => {
                        if (currenApplicant?.pets[i]?.pet_species === pet.name) {
                            return {
                                pet_id: currenApplicant?.pets[i]?.pet_id,
                                customer_id: this.getApplication.customer_id,
                                business_unit_id: this.getApplication.business_unit_id,
                                pet_weight: pet.weight,
                                pet_breed: pet.breed,
                                pet_owner_party_id: currenApplicant?.pets[i]?.pet_owner_party_id,
                                pet_species: pet.name,
                                pet_sex: currenApplicant?.pets[i]?.pet_sex,
                                age: pet.age,
                            };
                        }

                        return {
                            customer_id: this.getApplication.customer_id,
                            business_unit_id: this.getApplication.business_unit_id,
                            pet_weight: pet.weight,
                            pet_breed: pet.breed,
                            pet_owner_party_id: currenApplicant?.person_id,
                            pet_species: pet.name,
                            pet_sex: null,
                            age: pet.age,
                        };
                    });
                } else {
                    pets = currenApplicant?.pets?.map((petObj, i) => {
                        if (petsDocInstance[i]?.name === petObj.pet_species) {
                            return {
                                pet_id: petObj.pet_id,
                                customer_id: this.getApplication.customer_id,
                                business_unit_id: this.getApplication.business_unit_id,
                                pet_weight: petsDocInstance[i].weight,
                                pet_breed: petsDocInstance[i].breed,
                                pet_owner_party_id: petObj.pet_owner_party_id,
                                pet_species: petsDocInstance[i].name,
                                pet_sex: petObj.pet_sex,
                                age: petObj.age,
                            };
                        }
                        return {
                            pet_id: petObj.pet_id,
                            customer_id: this.getApplication.customer_id,
                            business_unit_id: this.getApplication.business_unit_id,
                            pet_weight: petObj.pet_weight,
                            pet_breed: petObj.pet_breed,
                            pet_owner_party_id: currenApplicant?.person_id,
                            pet_species: petObj.pet_species,
                            pet_sex: petObj.pet_sex,
                            age: petObj.age,
                        };
                    });
                }
                break;
            }
            return pets;
        },

        mapCoApplicants(persons) {
            let co_applicants =
                persons
                    ?.filter(
                        person => person.person_role.toLowerCase() === 'applicant' && person.contact_id !== this.currentProfile.contactId
                    )
                    ?.map(applicant => {
                        return {
                            person_id: applicant.person_id,
                            name: `${applicant.first_name} ${applicant.last_name}`,
                            email: applicant.email,
                        };
                    }) ?? [];

            const coApplicantsDocInstance = this.getDocumentInstance?.co_applicants?.filter(applicant => applicant.name !== null) ?? [];

            switch (true) {
            case coApplicantsDocInstance?.length && !co_applicants?.length:
                co_applicants = coApplicantsDocInstance;
                break;
            default:
                if (coApplicantsDocInstance?.length > co_applicants?.length) {
                    co_applicants = coApplicantsDocInstance.map((applicant, i) => {
                        if (co_applicants[i]?.email === applicant.email) {
                            return {
                                person_id: co_applicants[i].person_id,
                                name: co_applicants[i].name,
                                email: co_applicants[i].email,
                            };
                        }

                        return {
                            name: coApplicantsDocInstance[i].name,
                            email: coApplicantsDocInstance[i].email,
                        };
                    });
                }
                break;
            }
            return co_applicants;
        },

        createApplicationRecord(document_instance) {
            const new_application = { ...this.getApplication };
            delete new_application.occupancy_id;
            delete new_application.householdCombinedStatus;
            delete new_application.householdHasNote;
            const currentApplicant = new_application.persons.find(person => person.contact_id === this.currentProfile.contactId);
            const currentParty = new_application.parties?.find(party => party.contact_id === this.currentProfile.contactId);

            delete new_application.persons;

            this.$leasingServiceDataProvider
                .create('application', {
                    data: {
                        ...new_application,
                        contact_id: this.currentProfile.contactId,
                        household_combined_status: 'In progress',
                        expected_lease_duration: `${new_application.expected_lease_duration}`,
                        quoted_rent_amount: this.formatQuotedRentAmount(new_application.quoted_rent_amount),
                        home_phone: currentApplicant?.home_phone ?? document_instance.applicant_home_phone,
                        work_phone: currentApplicant?.work_phone ?? document_instance.current_employer_phone,
                        cell_phone: currentApplicant?.cell_phone ?? document_instance.applicant_cell_phone,
                        first_name: document_instance.applicant_first_name,
                        middle_name: document_instance.applicant_middle_name ?? '',
                        last_name: document_instance.applicant_last_name,
                        email: document_instance.current_email,
                        birth_date: document_instance.applicant_birth_date,
                        marital_status: document_instance.applicant_marital_status,
                        bluemoon_application_id: document_instance.id,
                        submission_date: null,
                        document_url: this.getDocumentInstance.document_url,
                        lease_id: this.getApplication.occupancy_id,
                        document_package_instance_id: document_instance.document_package_instance_id,
                        application_process_status: 'In progress',
                        event_description: 'Application started',
                        event_name: 'Application started',
                        pet: currentApplicant?.pets ?? this.preFillAppData.pets,
                        vehicle: currentApplicant?.vehicles ?? this.preFillAppData.vehicles,
                        person_role: currentApplicant?.person_role,
                        is_primary: currentParty?.is_primary,
                    },
                })
                .then(async res => {
                    this.setApplicationId(res.application_id);
                    await this.fetchApplicationByContactId(this.currentProfile.occupancyId);
                })
                .catch(error => {
                    this.notifyError(error.message);
                })
                .finally(() => (this.loading = false));
        },

        async save(document_instance) {
            this.loading = true;
            if (this.document_instance_created) {
                try {
                    await this.$leasingServiceDataProvider.patch('update_package_instance', {
                        document_package_instance_id: this.getDocumentInstance.document_package_instance_id,
                        business_unit_id: this.currentProfile.communityId,
                        customer_id: this.currentProfile.customerId,
                        data: {
                            partner_id: 'bdef439a-909e-4a28-a516-4aba4a669f85', // Bluemoon's id
                            document_type_name: 'BlueMoon Application',
                            document_initiated_by_app: 'Blue Moon',
                            occupancy_id: this.currentProfile.occupancyId,
                            payload: {
                                ...document_instance,
                                unit_number: this.getApplication?.unit_name ? this.getApplication?.unit_name : null,
                            },
                        },
                    });
                    if (document_instance.signed) {
                        await this.updateApplicationRecord(
                            document_instance,
                            'Application submitted - Not paid',
                            "Application submitted but hasn't been paid yet",
                            'Application submitted (not paid)'
                        );
                        this.$router.push({
                            name: 'application_service.application_payment',
                            params: { propertyId: this.property.id },
                        });
                    } else {
                        this.setDocumentInstance(document_instance);
                        await this.updateApplicationRecord(document_instance);
                    }
                } catch {
                    this.notifyError(UPDATE_DOCUMENT_INSTANCE_ERROR);
                } finally {
                    this.loading = false;
                }
            } else if (!this.document_instance_created) {
                try {
                    const res = await this.$leasingServiceDataProvider.create('create_package_instance', {
                        data: {
                            occupancy_id: this.currentProfile.occupancyId,
                            business_unit_id: this.currentProfile.communityId,
                            customer_id: this.currentProfile.customerId,
                            partner_id: 'bdef439a-909e-4a28-a516-4aba4a669f85', // Bluemoon's id
                            document_type_name: 'BlueMoon Application',
                            document_initiated_by_app: 'Blue Moon',
                            payload: document_instance,
                        },
                    });
                    this.setDocumentInstance({
                        ...document_instance,
                        document_package_instance_id: res.document_package_instance_id,
                    });
                    this.document_instance_created = true;
                    await this.createApplicationRecord({
                        ...document_instance,
                        document_package_instance_id: res.document_package_instance_id,
                    });
                } catch {
                    this.notifyError(SAVE_DOCUMENT_INSTANTCE_ERROR);
                } finally {
                    this.loading = false;
                }
            }
        },

        checkRequiredFields() {
            const errors = [];

            // Validate applicant's birth date
            if (this.getDocumentInstance.applicant_birth_date === null) {
                errors.push('birth date must not be empty');
            }

            // Validate applicant's social security number
            if (this.getDocumentInstance.applicant_ssn === null) {
                errors.push('social security number must not be empty');
            }

            // Validate applicant's email format
            if (this.getDocumentInstance.current_email === null) {
                errors.push('email must not be empty');
            } else {
                const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                if (!emailPattern.test(this.getDocumentInstance.current_email)) {
                    errors.push('email format is not valid');
                }
            }

            // Validate address field
            if (this.getDocumentInstance.current_address === null) {
                errors.push('address must not be empty');
            }

            // Validate current city field
            if (this.getDocumentInstance.current_city === null) {
                errors.push('city must not be empty');
            }

            // Validate state field
            if (this.getDocumentInstance.current_st === null) {
                errors.push('state must not be empty');
            }

            // Validate zip code field
            if (this.getDocumentInstance.current_zip === null) {
                errors.push('zip code must not be empty');
            }

            return errors;
        },

        async fetchApplicationByContactId(occupancy_id) {
            try {
                const res = await this.$leasingServiceDataProvider.getOne('household', { occupancy_id });
                const currentApplicant = res[0].persons.find(person => person.contact_id === this.currentProfile.contactId);
                this.setApplication({ ...res[0], application_id: res[0].persons?.[0]?.application?.[0]?.application?.application_id });
                if (currentApplicant?.application[0]?.application?.document_package_instance_id) {
                    await this.fetchDocumentIntance(
                        currentApplicant?.application[0]?.application?.document_package_instance_id,
                        currentApplicant.pets,
                        currentApplicant.vehicles
                    );
                } else {
                    this.preFillAppData = {
                        applicant_first_name: currentApplicant.first_name,
                        applicant_last_name: currentApplicant.last_name,
                        applicant_home_phone: currentApplicant.home_phone,
                        applicant_cell_phone: currentApplicant.cell_phone,
                        current_employer_phone: currentApplicant.work_phone,
                        current_email: currentApplicant.email,
                        unit_number: res[0].unit_name,
                        co_applicants: this.mapCoApplicants(res[0].persons),
                        occupants: this.mapOccupants(res[0]),
                        pet: currentApplicant?.pets?.length
                            ? currentApplicant?.pets?.map(pet => {
                                return {
                                    pet_id: pet.pet_id,
                                    age: pet.pet_age ?? '',
                                    name: pet.pet_name,
                                    breed: pet.pet_breed,
                                    weight: pet.pet_weight ? pet.pet_weight : '',
                                    pet_owner_party_id: currentApplicant.person_id,
                                };
                            })
                            : [],

                        vehicle: currentApplicant?.vehicles?.length
                            ? currentApplicant?.vehicles?.map(vehicle => {
                                return {
                                    vehicle_id: vehicle.vehicle_id,
                                    make: vehicle.vehicle_make,
                                    year: vehicle.vehicle_year ?? '',
                                    color: vehicle.vehicle_color,
                                    model: vehicle.vehicle_model,
                                    license_no: vehicle.vehicle_license_plate_text,
                                    license_st: vehicle.license_plate_country,
                                    primary_driver_party_id: currentApplicant.person_id,
                                };
                            })
                            : [],
                    };
                }
            } catch (error) {
                this.notifyError(error.message);
            }
        },

        async fetchDocumentIntance(document_id, petsApp, vehiclesApp) {
            await this.$leasingServiceDataProvider
                .getOne('document_instance', {
                    document_package_instance_id: document_id,
                    business_unit_id: this.getApplication.business_unit_id,
                    customer_id: this.getApplication.customer_id,
                })
                .then(res => {
                    if (res.document_package_instance_id) {
                        this.document_instance_created = true;
                    }

                    let pets = res.payload?.pets.filter(pet => pet.name !== null);
                    let vehicles = res.payload?.vehicles.filter(vehicle => vehicle.make !== null);

                    if (pets?.length > petsApp?.length) {
                        pets = pets.map((pet, i) => {
                            if (petsApp[i]?.pet_name === pet.name) {
                                return {
                                    pet_id: petsApp[i]?.pet_id,
                                    age: pet.age ?? '',
                                    name: pet.pet_name,
                                    breed: pet.pet_breed,
                                    weight: pet.pet_weight ? `${pet.pet_weight}` : '',
                                };
                            }
                            return { ...pet };
                        });
                    } else {
                        pets = petsApp.map(pet => {
                            return {
                                pet_id: pet.pet_id,
                                age: pet.age ?? '',
                                name: pet.pet_name,
                                breed: pet.pet_breed,
                                weight: pet.pet_weight ? `${pet.pet_weight}` : '',
                            };
                        });
                    }

                    if (vehicles?.length > vehiclesApp?.length) {
                        vehicles = vehicles.map((vehicle, i) => {
                            if (vehiclesApp[i]?.vehicle_make === vehicle.make) {
                                return {
                                    vehicle_id: vehiclesApp[i]?.vehicle_id,
                                    make: vehicle.make,
                                    year: vehicle.year,
                                    color: vehicle.color,
                                    model: vehicle.model,
                                    license_no: vehicle.license_no,
                                    license_st: vehicle.license_st,
                                };
                            }
                            return { ...vehicle };
                        });
                    } else {
                        vehicles = vehiclesApp.map(vehicle => {
                            return {
                                vehicle_id: vehicle.vehicle_id,
                                make: vehicle.vehicle_make,
                                year: vehicle.vehicle_year,

                                color: vehicle.vehicle_color,
                                model: vehicle.vehicle_model,
                                license_no: vehicle.vehicle_license_plate_text,
                                license_st: vehicle.license_plate_region,
                            };
                        });
                    }
                    this.setDocumentInstance({
                        ...res.payload,
                        document_package_instance_id: document_id,
                        pet: pets ?? [],
                        vehicle: vehicles ?? [],
                        co_applicants: this.mapCoApplicants(this.getApplication.persons),
                    });
                    this.preFillAppData = {
                        applicant_first_name: res.payload.applicant_first_name,
                        applicant_last_name: res.payload.applicant_last_name,
                        applicant_home_phone: res.payload.applicant_home_phone,
                        applicant_cell_phone: res.payload.applicant_cell_phone,
                        current_employer_phone: res.payload.current_employer_phone,
                        current_email: res.payload.current_email,
                        unit_number: res.payload.unit_number,
                        pet: pets ?? [],
                        vehicle: vehicles ?? [],
                        co_applicants: this.mapCoApplicants(this.getApplication.persons),
                    };
                })
                .catch(error => {
                    this.notifyError(error.message);
                });
        },

        mapMaritalStatus(status) {
            const marital_status = {
                SINGLE: 'Single',
                MARRIED: 'Married',
            }[status];
            return marital_status;
        },
    },
};
</script>
